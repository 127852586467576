<template>
    <!-- compose email -->
    <vs-popup title="Create Batch of Coupons" :active.sync="showModal">
        <div id="div-with-loading" class="vs-con-loading__container">
            <div class="vs-row">
                <div class="vs-col w-full">
                    <DiscountSelect @selected="setDiscount" />
                </div>

                <div class="vs-col w-full">
                    <BrokerSelect
                        @selectedBroker="setBroker"
                        class="mb-3"
                    ></BrokerSelect>
                </div>

                <div class="vs-col w-full mb-3">
                    <vs-input
                        class="w-full"
                        type="number"
                        v-model="formData.quantity"
                        label="Quantity"
                    />
                </div>
                <div class="vs-col w-full">
                    <vs-textarea
                        v-model="formData.description"
                        label="Description"
                    />
                </div>
            </div>

            <div class="vs-row">
                <vs-divider />
                <div class="flex">
                    <vs-button
                        v-on:click="batchCreate"
                        color="success"
                        class="mr-3"
                        :disabled="!validatedForm"
                    >
                        Create
                    </vs-button>

                    <vs-button v-on:click="close" type="border" class="mr-3"
                        >Cancel
                    </vs-button>
                </div>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import BrokerSelect from '../../../../../components/common/BrokerSelect'
import DiscountSelect from '../../../../../components/common/DiscountSelect'

export default {
    name: 'CouponBatchCreateModal',
    props: ['show'],
    mixins: [functions],
    components: {
        BrokerSelect,
        DiscountSelect,
    },
    computed: {
        title() {
            if (this.batch) return `Batch - ${this.batch.description}`
            return 'Batch'
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                if (value === false) this.close()
            },
        },
        validatedForm() {
            return (
                this.formData.discount &&
                this.formData.broker &&
                this.formData.quantity &&
                this.formData.description
            )
        },
    },
    data() {
        return {
            formData: {
                quantity: null,
                description: null,
                broker: null,
                discount: null,
            },
        }
    },
    methods: {
        close() {
            this.$emit('close')
            this.clearForm()
        },
        setBroker(value) {
            if (value) this.formData.broker = value.value
            else this.formData.broker = null
        },
        clearForm() {
            this.formData.discount = null
            this.formData.description = null
            // this.formData.broker= null
            // this.formData.quantity = null
        },
        setDiscount(value) {
            this.formData.discount = value.value
        },
        batchCreate() {
            http.post('coupons_batch_list_detail', this.formData)
                .then((response) => {
                    this.$vs.notify({
                        text: 'Coupons Created',
                        color: 'success',
                    })
                    this.$emit('refresh')
                    this.close()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        clearErrors() {
            this.errors.code = null
            this.errors.price_discount_percentage = null
            this.errors.price_fixed_joint = null
            this.errors.price_fixed_single = null
            this.errors.partner_code = null
        },
        errorHandler(error) {
            if (error.code) {
                this.errors.code = error.code[0]
            }
            if (error.broker) {
                this.errors.broker = error.broker[0]
            }
            if (error.user) {
                this.errors.user = error.user[0]
            }
            if (error.price_discount_percentage) {
                this.errors.price_discount_percentage =
                    error.price_discount_percentage[0]
            }
            if (error.partner_code) {
                this.errors.partner_code = error.partner_code[0]
            }
            if (error.price_fixed_joint) {
                this.errors.price_fixed_joint = error.price_fixed_joint[0]
            }
            if (error.price_fixed_single) {
                this.errors.price_fixed_single = error.price_fixed_single[0]
            }
            if (error.non_field_errors) {
                this.$vs.notify({
                    text: error.non_field_errors[0],
                    color: 'secondary',
                })
            }
        },
    },
}
</script>
