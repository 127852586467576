<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        :data="couponsBatch"
        @sort="handleSort"
        @search="search"
        @selected="selectedBatch"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item v-on:click="$emit('createNewBatch')">
                        Create New Batch of Coupons
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>Broker Batch Id</vs-th>
            <vs-th>Broker</vs-th>
            <vs-th>Description</vs-th>
            <vs-th>Date Created</vs-th>
            <vs-th>Discount</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr
                    :data="batch"
                    :key="index"
                    v-for="(batch, index) in data"
                >
                    <vs-td>
                        {{ batch.batch_id }}
                    </vs-td>

                    <vs-td>
                        <a
                            v-if="batch.broker"
                            class="link"
                            @click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: batch.broker.email },
                                })
                            "
                        >
                            {{ batch.broker.company_name }}</a
                        >
                    </vs-td>

                    <vs-td>
                        {{ batch.description }}
                    </vs-td>

                    <vs-td>
                        {{ formatDateAndTime(batch.date_created) }}
                    </vs-td>

                    <vs-td>
                        {{ batch.discount }}
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'CouponsBatchTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage'],
    computed: {
        couponsBatch() {
            return this.data.data
        },
    },
    data() {
        return {
            selected: null,
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        selectedBatch(data) {
            this.$emit('selectedBatch', data)
        },
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
    },
}
</script>
