<template>
    <div class="w-full">
        <label for="" class="vs-input--label"
            ><span v-if="selected">Discount</span></label
        >
        <v-select
            v-model="selected"
            class="w-full"
            :options="discounts"
            placeholder="Discount"
        />
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'EmailTemplateSelect',
    components: {
        vSelect,
    },
    props: ['emailTemplate'],
    watch: {
        selected(value) {
            this.$emit('selected', value)
        },
    },
    data() {
        return {
            selected: null,
            discounts: [
                { value: 'FREE', label: 'Free' },
                { value: '90%OFF', label: '90% Off' },
                { value: '75%OFF', label: '75% Off' },
                { value: '50%OFF', label: '50% Off' },
                { value: '25%OFF', label: '25% Off' },
                { value: '20%OFF', label: '20% Off' },
                { value: '10%OFF', label: '10% Off' },
                { value: '5%OFF', label: '5% Off' },
            ],
        }
    },
}
</script>

<style></style>
