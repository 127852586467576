<template>
    <!-- compose email -->
    <vs-popup :title="title" :active.sync="showModal">
        <div id="div-with-loading" class="vs-con-loading__container">
            <div
                id="loading-container"
                class="vs-con-loading__container"
                v-if="batchData"
            >
                <p>Broker Batch Id : {{ batchData.batch.batch_id }}</p>
                <p>Broker : {{ batchData.batch.broker.company_name }}</p>
                <p>
                    Date Create :
                    {{ formatDateAndTime(batchData.batch.date_created) }}
                </p>
                <p>Description : {{ batchData.batch.description }}</p>
                <p>Coupons : {{ batchData.coupons_count }}</p>
            </div>
            <vs-divider />
            <div class="vs-row">
                <div class="flex">
                    <vs-button
                        @click="getCoupons('pdf')"
                        color="success"
                        class="mr-3"
                    >
                        Print
                    </vs-button>
                    <vs-button
                        @click="getCoupons('txt')"
                        color="success"
                        class="mr-3"
                    >
                        Download
                    </vs-button>
                    <vs-button @click="close" type="border" class="mr-3"
                        >Cancel
                    </vs-button>
                    <vs-button
                        v-on:click="couponDeleteConfirm"
                        type="border"
                        color="secondary"
                        icon-pack="feather"
                        icon="icon-trash"
                    ></vs-button>
                </div>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import { textFormat } from '@/mixins/TextFormat'
import * as JsPDF from 'jspdf'

export default {
    name: 'CouponBatchModal',
    props: ['show', 'batch'],
    mixins: [functions, textFormat],
    components: {},
    watch: {
        batch: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value) this.getCouponBatchInfo(value.id)
            },
        },
    },
    computed: {
        title() {
            if (this.batch) return `Batch - ${this.batch.description}`
            return 'Batch'
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                if (value === false) this.close()
            },
        },
        buttonSaveText() {
            if (this.coupon) return 'Save'
            return 'Create'
        },
    },
    data() {
        return {
            batchData: null,
            showLoading: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        setBroker(value) {
            if (value) {
                console.log('set broker value : ', value)
                this.couponData.broker = value.value
            } else this.couponData.broker = null
        },
        setUser(value) {
            if (value) {
                this.couponData.user = value.value
            } else this.couponData.user = null
        },
        getCouponBatchInfo(id) {
            http.get('coupon_batch_detail', { params: { id: id } })
                .then((response) => {
                    this.batchData = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getCoupons(action) {
            this.$vs.loading({
                background: 'primary',
                color: 'secondary',
                container: '#loading-container',
                scale: 0.45,
            })
            http.get('coupons_batch_list_detail', {
                params: { batch_id: this.batch.id },
            })
                .then((response) => {
                    this.codes = response.data
                    if (action === 'pdf') this.pdf()
                    else if (action === 'txt') this.txt()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close(
                        '#loading-container > .con-vs-loading'
                    )
                })
        },
        pdf() {
            var vouchers = new JsPDF({
                orientation: 'landscape',
                format: 'a5',
            })

            var xAddress = 129
            var yAddress = 90
            var fontSizeAddress = 9
            var xCode = 159
            var yCode = 103
            var fontSizeCode = 14

            vouchers.setFontSize(fontSizeAddress)
            vouchers.setFontStyle('normal')
            vouchers.text(
                'www.mylastwill.co.uk/coupon/' + this.codes[0],
                xAddress,
                yAddress
            )
            vouchers.setFontSize(fontSizeCode)
            vouchers.setFontStyle('bold')
            vouchers.text(this.codes[0], xCode, yCode)

            for (var i = 1; i < this.codes.length; i++) {
                vouchers.addPage('a5', 'landscape')
                vouchers.setFontSize(fontSizeAddress)
                vouchers.setFontStyle('normal')
                vouchers.text(
                    'www.mylastwill.co.uk/coupon/' + this.codes[i],
                    xAddress,
                    yAddress
                )
                vouchers.setFontSize(fontSizeCode)
                vouchers.setFontStyle('bold')
                vouchers.text(this.codes[i], xCode, yCode)
            }
            vouchers.save(
                this.batch.batch_id + '_' + this.batch.description + '.pdf'
            )
            this.$vs.loading.close('#loading-container > .con-vs-loading')
        },
        txt() {
            var txt = ''
            for (var i = 0; i < this.codes.length; i++) {
                txt += this.codes[i]
                if (this.codes.length - 1 !== i) txt += ', '
            }
            var hiddenElement = document.createElement('a')
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(txt)
            hiddenElement.target = '_blank'
            hiddenElement.download =
                this.batch.batch_id + '_' + this.batch.description + '.txt'
            hiddenElement.click()
            this.$vs.loading.close('#loading-container > .con-vs-loading')
        },
        couponDeleteConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: 'Delete',
                acceptText: 'Delete',
                text: `Delete Batch : '${this.batch.description}'`,
                accept: this.batchDelete,
            })
            this.$emit('close')
        },
        batchDelete() {
            console.log(this.batchData)
            http.delete('coupons_batch_list_detail', {
                params: { id: this.batchData.batch.id },
            }).then((response) => {
                this.$vs.notify({
                    text: 'Batch and associated Coupons Deleted',
                    color: 'success',
                })
                this.$emit('refresh')
            })
        },
        clearErrors() {
            this.errors.code = null
            this.errors.price_discount_percentage = null
            this.errors.price_fixed_joint = null
            this.errors.price_fixed_single = null
            this.errors.partner_code = null
        },
        errorHandler(error) {
            if (error.code) {
                this.errors.code = error.code[0]
            }
            if (error.broker) {
                this.errors.broker = error.broker[0]
            }
            if (error.user) {
                this.errors.user = error.user[0]
            }
            if (error.price_discount_percentage) {
                this.errors.price_discount_percentage =
                    error.price_discount_percentage[0]
            }
            if (error.partner_code) {
                this.errors.partner_code = error.partner_code[0]
            }
            if (error.price_fixed_joint) {
                this.errors.price_fixed_joint = error.price_fixed_joint[0]
            }
            if (error.price_fixed_single) {
                this.errors.price_fixed_single = error.price_fixed_single[0]
            }
            if (error.non_field_errors) {
                this.$vs.notify({
                    text: error.non_field_errors[0],
                    color: 'secondary',
                })
            }
        },
    },
}
</script>
